import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby';

import Layout from "../components/layout";
import SEO from "../components/seo";

import {filter} from 'lodash';

import AccordionElement from "../components/accordion/accordion_element";
import Accordion from "../components/accordion/accordion";


// Besucherinfo Page Component
const Faqs = props => {

    const language_id = 1;
    const faqs = filter(props.data.allFaqs.nodes, function(faq){ return faq.sys_languages_id == language_id});

    let onAccordionChange = data => {
        // console.log(data)
    };

    let isAccordionDefaultOpen = true;
    if (typeof window !== "undefined") {
        if (window.innerWidth < 720) {
            isAccordionDefaultOpen = false;
        }
    }

    const translations = {
        faqs : {
            1: "Frequently asked Questions",
            2: "Häufig gestellte Fragen"
        }
    }

    return (
        <Layout pathContext={props.pathContext}>
            <SEO title={`${translations.faqs[language_id]} – Virtulleum`} />

            <h1 className="main_content_wrapper text_align_center faq_headline">
                {translations.faqs[language_id]}
            </h1>
            <section className="faq_section accordion_wrapper main_content_wrapper">

                <Accordion
                    allowOnlyOneOpen={true}
                    onChange={onAccordionChange}
                >
                    {faqs.map((faq, index) => (
                        <AccordionElement
                            key={index}
                            title={faq.title}
                            defaultOpen={
                                index === 0 ? isAccordionDefaultOpen : false
                            }
                        >
                            <div
                                className="accordion_text_wrapper"
                                dangerouslySetInnerHTML={{
                                    __html: faq.description
                                }}
                            ></div>
                        </AccordionElement>
                    ))}
                </Accordion>
              
            </section>
        </Layout>
    );
};

export default Faqs

export const query = graphql`
    query FaqQueryEn {
        allFaqs {
            nodes {
                description
                title
                root_item_id: alternative_id
                sys_languages_id
            }
        }
    }
`
  